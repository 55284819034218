import React from "react"
import Seo from "../../components/seo"
import Trusted from "../../components/trusted"
import Faq from "../../components/faq"
import { useState, useLayoutEffect, useEffect } from "react"
import { CheckoutInfoContext } from "../../Contexts/ItemInfoCheckout"
import CheckoutContainer from "../../components/checkout/checkoutContainer"
import StickyCtaClosable from "../../components/landing-pages/stickyCtaClosable"
import { useContext } from "react"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import HeaderLp from "../../components/landing-pages/headerLp"
import saleDate from "../../utils/saleDate"
const CheckoutPage = props => {
  const { closedSticky, setClosedSticky } = useContext(GlobalAuthContext)

  //using useLayoutEffect instead of useEffect because we want state to change before rendering -> prevents flickering/bad UX
  //with this we check if we are passing some information with gatsby Link in location state
  useLayoutEffect(() => {
    // console.log("props", props)
    if (props.location.state !== null) {
      if (props.location.state.product !== undefined) {
        setAllInfo(props.location.state)
        setItemSelected(true)
      }
    } else {
      setItemSelected(false)
    }
  }, [])

  const [email, setEmail] = useState("")
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [path, setPath] = useState("")
  //state that manages checkout next step
  const [itemSelected, setItemSelected] = useState(false)

  //billing type and number of users
  const [billingType, setBillingType] = useState("")

  //state managing object with all checkout info
  const [allInfo, setAllInfo] = useState({})
  const [isChecked, setIsChecked] = useState(true)

  //state managing number of users per license
  //initial number of users
  const [initialNumber, setInitialNumber] = useState(1)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      let order = JSON.parse(localStorage.getItem("user"))
      setEmail(order.true_email)
      setFname(order.first_name)
      setLname(order.last_name)
    }
  }, [])

  const defaultPrice = {
    Checkout_1_visible: props.pageContext.node.checkout_1_visible,
    Checkout_1_best: props.pageContext.node.checkout_1_best,
    Checkout_2_visible: props.pageContext.node.checkout_2_visible,
    Checkout_2_best: props.pageContext.node.checkout_2_best,
    Checkout_3_visible: props.pageContext.node.checkout_3_visible,
    Checkout_3_best: props.pageContext.node.checkout_3_best,
  }
  return (
    <>
      <Seo title="Checkout" noindex={true} />
      {closedSticky == false && (
        // <StickyCtaClosable
        //   date={props.pageContext.node.end_sale}
        // ></StickyCtaClosable>
        <StickyCtaClosable
          date={`Get 38% off for a limited time! Sale ends ${saleDate()}.`}
        ></StickyCtaClosable>
      )}
      <HeaderLp
        wrapLocation={props.location}
        closedSticky={closedSticky}
      ></HeaderLp>
      <CheckoutInfoContext.Provider
        value={{
          itemSelected,
          setItemSelected,
          billingType,
          setBillingType,
          allInfo,
          setAllInfo,
          initialNumber,
          setInitialNumber,
          email,
          setEmail,
          fname,
          setFname,
          lname,
          setLname,
          path,
          setPath,
          isChecked,
          setIsChecked,
        }}
      >
        <CheckoutContainer
          wrapLocation={props.pageContext.location}
          priceInfo={defaultPrice}
        />
        {!itemSelected && (
          <>
            <Faq></Faq>
            <Trusted></Trusted>
          </>
        )}
      </CheckoutInfoContext.Provider>
    </>
  )
}

export default CheckoutPage
